import React, { useState, useEffect } from "react"
import { IMAGE } from "@/assets"
import { Button, Popup, Toast } from "antd-mobile"
import { JSONifError } from "@/tool"
import { post } from "@/service"
import "./styles.less"

const IndexComponent = (props) => {
  const { show, callBackFunc, toRefreshFunc } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(show)
  }, [show])

  async function proceed() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading...',
        duration: 0
      })
      await post("fight/bull_vs_bear_risk_confirm")
      Toast.clear()
      toRefreshFunc()
      setVisible(false)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSONifError(error)
      })
    }
  }

  return (
    <Popup
      visible={visible}
      closeOnMaskClick={false}
      destroyOnClose
      bodyClassName="risks-popup"
      onClose={() => setVisible(false)}
    >
      <div className="slide-view">
        <img src={IMAGE.risks} alt="" className="logo" />
        <p className="title">Before you begin, please take note of the following:</p>
        <p className="session-title">High-Risk Warning:</p>
        <p className="session-content">Betting on the rise or fall of Bitcoin (BTC) prices can lead to rapid profits or losses. If the direction of your bet is contrary to the market fluctuations, it could result in a partial or complete loss of your bet assets. Before using this feature, please ensure you have sufficient financial capacity and risk tolerance.</p>
        <br />
        <p className="session-content">FuturesCash does not guarantee profits to its users, nor does it share benefits or risks with them.</p>
        <br />
        <p className="session-content">We sincerely hope and recommend that users make a prudent decision about participating in this game based on their own risk tolerance and personal circumstances, and allocate their digital assets strategically.</p>
      </div>
      <div className="gradient-div"></div>
      <div className="button-div">
        <div className="back-button" onClick={() => {
          setVisible(false)
          callBackFunc()
        }}>
          <img src={IMAGE.back} alt="" />
        </div>
        <Button shape="rounded" block className="proceed-button" onClick={() => proceed()}>👌 I understand the risks, proceed</Button>
      </div>
    </Popup>
  )
}
export default IndexComponent
