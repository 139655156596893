export function getQueryVariable(variable) {
	var query = window.location.search.substring(1)
	var vars = query.split("&")
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=")
		if(pair[0] === variable){
			return decodeURIComponent(pair[1])
		}
	}
	return(false)
}

export function JSONifError(error) {
	const errorString = JSON.stringify(error)
	const errorStringWithoutQuotes = errorString.slice(1, -1)
	return errorStringWithoutQuotes
}
 
function getDecimalLength(num) {
	const str = num.toString()
	if (str.indexOf('.') !== -1) {
		return str.length - str.indexOf('.') - 1
	} else {
		return 0
	}
}

export function Add(a, b) {
	const c = Math.max(getDecimalLength(a), getDecimalLength(b))
	const d = Math.pow(10, c)
	return (a * d + b * d) / d
}

export function floorLocaleString(amount) {
	const _amount = parseFloat(amount)
	const __amount = parseFloat(Math.floor(_amount * 1000) / 1000)
	return __amount.toLocaleString()
}

export function ceilLocaleString(amount) {
	const _amount = parseFloat(amount)
	const __amount = parseFloat(Math.ceil(_amount * 1000) / 1000)
	return __amount.toLocaleString()
}

export function floorString(amount, digit = 3) {
	const _amount = parseFloat(amount)
	const _digit = Math.pow(10, digit)
	const __amount = parseFloat(Math.floor(_amount * _digit) / _digit)
	return __amount.toString()
}

export function ceilString(amount) {
	const _amount = parseFloat(amount)
	const __amount = parseFloat(Math.ceil(_amount * 1000) / 1000)
	return __amount.toString()
}