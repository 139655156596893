import axios from "axios"

const instance = axios.create({
  timeout: 60000,
  // baseURL: "http://10.88.10.70:8899/"
  // baseURL: "https://test.api.pifutures.org/"
  baseURL: "https://api.pifutures.org/"
})
// 请求拦截
instance.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("token")
    }
    return config
  }, 
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截
instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
      let response = res.data
      if (response.code === 0) {
        return response.data
      } else {
        return Promise.reject(response.message)
      }
    } else {
      return Promise.reject(res.statusText)
    }
  }, 
  error => {
    if (error.message.includes('timeout')) {
      return Promise.reject('Network Timeout')
    } else if (error.message.includes('Network Error')) {
      return Promise.reject('Network Error')
    } else {
      return Promise.reject('Network error, please try later!')
    }
  }
)
// 请求方法
export const post = function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url,params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export const get = function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
