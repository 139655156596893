import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Home, Record, Rules, NotFound } from "@/page"
import "./App.less"

const App = () => {

  useEffect(() => {
    const handleFocus = () => {
      if (!document.hidden) {
        window.location.reload()
      }
    }
    window.addEventListener('visibilitychange', handleFocus)
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/record/:asset/:type"><Record /></Route>
        <Route path="/rules"><Rules /></Route>
        <Route path="/*"><NotFound /></Route>
      </Switch>
    </Router>
  )
}

export default App