import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Popup, DotLoading, Input, NumberKeyboard, Toast, Modal } from "antd-mobile"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import { floorLocaleString, ceilLocaleString, floorString, JSONifError } from "@/tool"
import "./styles.less"

const IndexComponent = (props) => {
  const { tabIndex, data, toRefreshFunc, toUpdateFunc } = props
  const history = useHistory()
  const [transferIndex, setTransferIndex] = useState(0)
  const [depositIndex, setDepositIndex] = useState(0)
  const [transferVisible, setTransferVisible] = useState(false)
  const [depositTypeLoading, setDepositTypeLoading] = useState(false)
  const [depositType, setDepositType] = useState([])
  const [swapRate, setSwapRate] = useState(0)
  const [swapFeeDividing, setSwapFeeDividing] = useState(0)
  const [swapFeeDown, setSwapFeeDown] = useState(0)
  const [swapFeeUp, setSwapFeeUp] = useState(0)
  const [needFee, setNeedFee] = useState(false)
  const [flatFee, setFlatFee] = useState(false)
  const [flatFeeValue, setFlatFeeValue] = useState(0)
  const [afterSwapNumber, setAfterSwapNumber] = useState(0)
  const [minimumNumber, setMinimumNumber] = useState(0)
  const [lightAvailable, setLightAvailable] = useState(false)
  const [lightMinimum, setLightMinimum] = useState(false)
  const [lightWithdrawable, setLightWithdrawable] = useState(false)
  const [reload, setReload] = useState(false)
  const [amount, setAmount] = useState('')
  const [disableNext, setDisableNext] = useState(true)
  const [paymentVisible, setPaymentVisible] = useState(false)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [paymentPIN, setPaymentPIN] = useState('')

  useEffect(() => {
    async function getDepositType() {
      try {
        setDepositTypeLoading(true)
        const resp = await post("fight/canTransferInList")
        setDepositTypeLoading(false)
        setDepositType(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSONifError(error)
        })
      }
    }
    transferIndex === 0 && transferVisible && getDepositType()
  }, [transferIndex, transferVisible, reload])

  useEffect(() => {
    if (!transferVisible) {
      toUpdateFunc()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferVisible])

  useEffect(() => {
    setAmount('')
    setSwapRate(0)
    setMinimumNumber(0)
    setSwapFeeDividing(0)
    setSwapFeeDown(0)
    setSwapFeeDown(0)
    setAfterSwapNumber(0)
    setNeedFee(false)
    setFlatFee(false)
    setFlatFeeValue(0)
  }, [transferIndex, depositIndex])

  useEffect(() => {
    const _lightMinimum = (depositIndex === 0 && amount !== "" && depositType.length > 0 && parseFloat(amount) < parseFloat(depositType[0].minTransferIn)) || (depositIndex > 0 && amount !== "" && parseFloat(amount) < parseFloat(minimumNumber)) ? true : false
    setLightMinimum(_lightMinimum)
  }, [depositIndex, depositType, amount, minimumNumber])

  useEffect(() => {
    const _lightAvailable = transferIndex === 0 &&  depositType.length > depositIndex && amount !== "" && parseFloat(floorString(depositType[depositIndex].balance)) < parseFloat(amount) ? true : false
    setLightAvailable(_lightAvailable)
  }, [depositType, depositIndex, transferIndex, amount])

  useEffect(() => {
    const _lightWithdrawable = transferIndex > 0 && amount !== "" && parseFloat(floorString(data.withdrawable)) < parseFloat(amount) ? true : false
    setLightWithdrawable(_lightWithdrawable)
  }, [transferIndex, amount, data.withdrawable])

  useEffect(() => {
    if (amount.length > 0 && swapFeeDividing > 0 && swapFeeDown > 0 && swapFeeUp > 0 && swapRate > 0) {
      const swapedAmout = parseFloat(amount) * swapRate
      const _afterSwapNumber = needFee ? flatFee ? swapedAmout - flatFeeValue : swapedAmout > swapFeeDividing ? swapedAmout * (1 - swapFeeUp) : Math.max(0, swapedAmout - swapFeeDown) : swapedAmout
      setAfterSwapNumber(_afterSwapNumber)
    } else {
      setAfterSwapNumber(0)
    }
  }, [amount, swapFeeDividing, swapFeeDown, swapFeeUp, swapRate, needFee, flatFee, flatFeeValue])

  useEffect(() => {
    async function getSwapToUSDT() {
      try {
        const resp = await post("app/swap/getSwapParameterV2", {
          fromToken: depositType[depositIndex].asset,
          fromAmount: 100,
          toToken: "USDT",
          toAmount: 0,
          swapType: "INPUT",
          forSwap: true
        })
        const _minFromAmount = resp.needFee ? resp.flatFee ? Math.max((resp.flatFeeValue + 0.001) * resp.btoARate, resp.minFromAmount) : Math.max((resp.swapFeeDown + 0.001) * resp.btoARate, resp.minFromAmount) : Math.max(0.001 * resp.btoARate, resp.minFromAmount)
        setMinimumNumber(_minFromAmount)
        setSwapFeeDividing(resp.swapFeeDividing)
        setSwapFeeDown(resp.swapFeeDown)
        const _swapFeeUp = parseFloat(resp.swapFeeUp)
        setSwapFeeUp(_swapFeeUp * 0.001)
        setNeedFee(resp.needFee)
        setFlatFee(resp.flatFee)
        setFlatFeeValue(resp.flatFeeValue)
      } catch (error) {
        setMinimumNumber(0)
        setSwapFeeDown(0)
        setSwapFeeDown(0)
        setAfterSwapNumber(0)
        setNeedFee(false)
        setFlatFee(false)
        setFlatFeeValue(0)
        Toast.show({
          icon: 'fail',
          content: JSONifError(error)
        })
      }
    }
    transferIndex === 0 && transferVisible && depositIndex !== 0 && depositType.length > 0 && depositType[depositIndex].asset !== "USDT" && getSwapToUSDT()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferVisible, transferIndex, depositIndex, depositType])

  useEffect(() => {
    async function getSwapToUSDT() {
      try {
        const resp = await post("app/swap/getSwapParameterV2", {
          fromToken: depositType[depositIndex].asset,
          fromAmount: amount,
          toToken: "USDT",
          toAmount: 0,
          swapType: "INPUT",
          forSwap: true
        })
        setSwapRate(resp.atoBRate)
      } catch (error) {
        setSwapRate(0)
        Toast.show({
          icon: 'fail',
          content: JSONifError(error)
        })
      }
    }
    transferIndex === 0 && transferVisible && depositIndex !== 0 && depositType.length > 0 && depositType[depositIndex].asset !== "USDT" && amount.length > 0 && getSwapToUSDT()
  }, [transferVisible, transferIndex, depositIndex, depositType, amount])

  async function resetDemoBalance() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading...',
        duration: 0
      })
      await post("fight/demoBalanceReset")
      Toast.clear()
      toRefreshFunc()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSONifError(error)
      })
    }
  }

  useEffect(() => {
    const _disableNext = transferIndex === 0 ? (depositType.length === 0 || amount.length === 0 || lightMinimum || lightAvailable) ? true : depositType[depositIndex].asset === "USDT" ? false : swapRate === 0 ? true : false
    : amount.length === 0 || data.securityLevel < 3 || lightWithdrawable ? true : false
    setDisableNext(_disableNext)
  }, [transferIndex, depositType, amount, data, swapRate, depositIndex, lightMinimum, lightAvailable, lightWithdrawable])

  async function transfer() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading...',
        duration: 0
      })
      const asset = transferIndex === 0 ? depositType[depositIndex].asset : "USDT"
      const direction = transferIndex === 0 ? "IN" : "OUT"
      await post("fight/transfer/v2", {
        asset, amount, paymentPIN, direction
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: "Transfer successful",
      })
      setTimeout(() => {
        setReload(!reload)
        toUpdateFunc()
      }, 2000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSONifError(error)
      })
    }
  }

  function openActiveWithdrawLimitPage() {
    try {
      window.gotoSecurityLevelPage.postMessage("")
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: "Please update your app or access your profile for an upgrade."
      })
    }
  }

  return (
    <div className="info-component">
      <div className="container">
        <div className="balance-div">
          <h1>{`${floorLocaleString(data.balance)} ${data.asset}`}</h1>
          {
            tabIndex === 0 && <h2>{`${floorLocaleString(data.withdrawable)} Withdrawable`}</h2>
          }
        </div>
        {
          tabIndex === 0 ? <div className="button-div">
          {
            [IMAGE.transferin, IMAGE.transferout].map((item, index) => {
              return <img src={item} alt="" key={index} onClick={() => {
                setTransferIndex(index)
                setTransferVisible(true)
              }}  />
            })
          }
          </div> : <div className="button-div">
            <img src={IMAGE.reset} alt="" onClick={() => resetDemoBalance()} />
          </div>
        }
      </div>
      <Popup
        id="popup"
        visible={transferVisible}
        closeOnMaskClick
        destroyOnClose
        bodyClassName="transfer-popup"
        bodyStyle={{ height: 'calc(100vh - 100px)'  }}
        onClose={() => {
          setTransferVisible(false)
          setAmount('50')
        }}
      >
        <div className="tool">
          <img src={IMAGE.close} alt="" onClick={() => {
            setTransferVisible(false)
            setAmount('50')
          }} />
          <img src={IMAGE.transferRecord} alt="" onClick={() => history.push(`/record/${data.asset}/transfer`)} />
        </div>
        <div className="tab-div">
        {
          ["Deposit", "Withdraw"].map((item, index) => {
            return <h1 className={`tab-item ${transferIndex === index ? 'tab-item-active' : ''}`} key={index} onClick={() => setTransferIndex(index)}>{ item }</h1>
          })
        }
        </div>
        {
          transferIndex === 0 && <>{
            depositTypeLoading ? <div className="assets-div" style={{ justifyContent: 'center' }}>
              <DotLoading color="#808080" />
            </div> : <div className="assets-div">
            {
              depositType.map((item, index) => {
                return <div className={`assets-item ${depositIndex === index ? "assets-item-active" : ""}`} key={index} onClick={() => setDepositIndex(index)}>
                  <div>
                    <img src={item.icon} alt="" />
                    <h1>{ item.asset }</h1>
                  </div>
                  {
                    item.bonus > 0 && <div className="bonus-div">
                      <img src={IMAGE.bonus} alt="" />
                      <h1>{ `Bonus +${item.bonus * 100}%` }</h1>
                    </div>
                  }
                </div>
              })
            }
            </div>
          }</>
        }
        <div className="amount-container">
          <div className="input-container">
            <div className="input-div">
              <Input className="input" placeholder="0" value={amount} onChange={(value) => {
                const regex = /^\d*\.?\d{0,3}$/
                if (regex.test(value)) {
                  setAmount(value);
                }
              }} />
              {
                transferIndex === 0 && depositType.length > depositIndex && <h1 className="asset">{ depositType[depositIndex].asset }</h1>
              }
              {
                transferIndex === 1 && <h1 className="asset">USDT</h1>
              }
            </div>
            {
              transferIndex === 0 && depositType.length > depositIndex && <div className="max-div">
                <h2 style={{ color: lightAvailable ? '#FF5151' : '#808080' }}>{`${floorLocaleString(depositType[depositIndex].balance)} Available`}</h2>
                <h1 onClick={() => setAmount(floorString(depositType[depositIndex].balance))}>Max</h1>
              </div>
            }
            {
              transferIndex === 1 && <div className="max-div">
                <h2 style={{ color: lightWithdrawable ? '#FF5151' : '#808080' }}>{`${ floorLocaleString(data.withdrawable)} Withdrawable`}</h2>
                <h1 onClick={() => setAmount(floorString(data.withdrawable))}>Max</h1>
              </div>
            }
            {
              transferIndex === 0 && depositType.length > depositIndex && <h2 className="minimum" style={{ color: lightMinimum ? '#FF5151' : '#808080' }}>{`${
                depositIndex === 0 ? ceilLocaleString(depositType[0].minTransferIn) : minimumNumber === 0 ? '-' :  ceilLocaleString(minimumNumber)
              } Minimum`}</h2>
            }
          </div>
          {
            transferIndex === 0 && depositType.length > 0 && depositType[depositIndex].asset !== "USDT" && <div className="fcc-bonus">
              <h1>{ swapRate === 0 || amount === "" ? '-' : `${floorLocaleString(afterSwapNumber)}` }</h1>
              <h1>USDT</h1>
            </div>
          }
        </div>
        {
          ((transferIndex === 0 && depositType.length > depositIndex && depositType[depositIndex].hasBonusTime > 0) || transferIndex > 0) && <img src={IMAGE.arrowBottom} alt="" className="arrow" />
        }
        {
          transferIndex === 0 ? 
            depositType.length > depositIndex && depositType[depositIndex].hasBonusTime > 0 ? <div className="computation-div">
              <div className="computation-item">
                {/* <h2>{`
                      + Bonus 
                      ${depositType[depositIndex].hasBonusTime > 0 ? `(Only ${depositType[depositIndex].hasBonusTime} times)` : ''} 
                    `}
                </h2> */}
                <h2>{`
                      + Bonus 
                      ${depositType[depositIndex].hasBonusTime > 0 ? `(First-Time)` : ''} 
                    `}
                </h2>
                <h1>{`
                  ${
                    amount === '' ? 0 : 
                    depositIndex === 0 ? 
                      parseFloat(amount) < 1 ? 
                        0 : 
                        floorLocaleString(parseFloat(amount) * parseFloat(depositType[depositIndex].bonus)) : 
                      swapRate === 0 ?
                        '-' :  
                        parseFloat(floorLocaleString(afterSwapNumber)) < 1 ? 
                          0 : 
                          floorLocaleString(afterSwapNumber * parseFloat(depositType[depositIndex].bonus))
                  } USDT`
                }</h1>
              </div>
              {
                ((depositIndex === 0 && amount !== "" && parseFloat(amount) < 1) || (depositIndex > 0 && swapRate > 0 && amount !== "" && parseFloat(floorLocaleString(afterSwapNumber)) < 1)) && <div className="computation-item" style={{ padding: '0', marginTop: '-6px' }}>
                  <h2>• Minimum deposit: 1 USDT</h2>
                </div>
              }
              <div className="computation-item">
                <h2>= Total Received</h2>
                <h1 style={{ fontSize: '18px' }}>{`
                  ${
                    amount === '' ? 0 : 
                    depositIndex === 0 ? 
                      parseFloat(amount) < 1 ? 
                        floorLocaleString(amount) :
                        floorLocaleString(parseFloat(amount) * (parseFloat(depositType[depositIndex].bonus) + 1)) : 
                      swapRate === 0 ?
                        '-' :
                        parseFloat(floorLocaleString(afterSwapNumber)) < 1 ? 
                          floorLocaleString(afterSwapNumber) :
                          floorLocaleString(parseFloat(afterSwapNumber) * (parseFloat(depositType[depositIndex].bonus) + 1))
                  } USDT`
                }</h1>
              </div>
            </div> : 
            <></> : 
          <div className="infomation-div">
            <div className="info-desc">
              <img src={data.avatar} alt="" />
              <h1>{data.username}</h1>
              {
                data.securityLevel < 3 && <h2>Lv3 Required</h2>
              }
            </div>
            {
              data.securityLevel < 3 &&  <div className="upgrade-button" onClick={() => openActiveWithdrawLimitPage()}>
                <h1>Upgrade</h1>
                <img src={IMAGE.arrowRight} alt="" />
              </div>
            }
          </div>
        }
        <div style={{ flex: '1' }}></div>
        <Button shape="rounded" block disabled={disableNext} className="next-button" onClick={() => {
          setPaymentPIN("")
          setPaymentVisible(true)
          setKeyboardVisible(true)
        }}>
          <h1>Next</h1>
        </Button>
      </Popup>
      <Modal 
        visible={paymentVisible} 
        header={null}
        bodyClassName="payment-pin-modal"
        bodyStyle={{ marginBottom: keyboardVisible ? '200px' : 0 }}
        content={
          <div className="modal-content-div">
            <img src={IMAGE.close} alt="" className="close-button" onClick={() => {
              setPaymentVisible(false)
              setKeyboardVisible(false)
            }} />
            <p className="title">Verify your payment PIN</p>
            <div className="paymentPIN-div" onClick={() => setKeyboardVisible(true)}>
            {
              Array.from({ length: 6 }).map((_, index) => {
                return <div key={index} className="single-pin">
                  {
                    paymentPIN.length > index && <div className="point"></div>
                  }
                </div>
              })
            } 
            </div>
          </div>
        } 
      />
      <NumberKeyboard
        visible={keyboardVisible}
        onClose={() => setKeyboardVisible(false)}
        onInput={(value) => setPaymentPIN(v => v.length < 6 ? v + value : v)}
        onDelete={() => setPaymentPIN(v => v.slice(0, v.length - 1))}
        confirmText='Done'
        onConfirm={() => {
          if (paymentPIN.length === 6) {
            setPaymentVisible(false)
            transfer()
          }
        }}
      />
    </div>
  )
}
export default IndexComponent
