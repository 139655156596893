import React from "react"
import { IMAGE } from "@/assets"
import { useHistory } from "react-router-dom"
import "./styles.less"

const IndexComponent = (props) => {
  const history = useHistory()
  const { tabs, tabIndex, change } = props

  return (
    <div className="tool-component">
      <div className="tab-div">
      {
        tabs.map((item, index) => {
          return <h1 className={`tab-item ${tabIndex === index ? 'tab-item-active' : ''}`} key={index} onClick={() => {
            sessionStorage.setItem("tabIndex", `${index}`)
            change(index)
          }}>{ item }</h1>
        })
      }
      </div>
      <div className="botton-div">
        <img src={IMAGE.record} alt="" onClick={() => history.push(`/record/${tabs[tabIndex].toUpperCase()}/battle`)} />
        <img src={IMAGE.rule} alt="" onClick={() => history.push('/rules')} />
      </div>
    </div>
  )
}
export default IndexComponent
