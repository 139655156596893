import React from "react"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = (props) => {
  const { title, hideBack, goBackFunction } = props

  function close() {
    window.closeWebview.postMessage("")
  }

  return (
    <div className="navbar-component">
      <img src={IMAGE.back} alt="" style={{ visibility: hideBack ? 'hidden' : 'visible' }} onClick={goBackFunction} />
      <h1>{ title }</h1>
      <img src={IMAGE.close} alt="" onClick={() => close()}  />
    </div>
  )
}
export default IndexComponent
