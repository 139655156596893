import back from "./assets/back.svg"
import close from "./assets/close.svg"
import record from "./assets/record.svg"
import rule from "./assets/rule.svg"
import transferin from "./assets/transferin.svg"
import transferout from "./assets/transferout.svg"
import transferin2 from "./assets/transferin2.svg"
import transferout2 from "./assets/transferout2.svg"
import reset from "./assets/reset.svg"
import transferRecord from "./assets/transferRecord.svg"
import usdt from './assets/usdt.svg'
import fcc from './assets/fcc.svg'
import arrowBottom from './assets/arrow-bottom.svg'
import arrowRight from './assets/arrow-right.svg'
import bull from './assets/bull.png'
import bull3 from './assets/bull3.png'
import bear from './assets/bear.png'
import bear3 from './assets/bear3.png'
import up from './assets/up.svg'
import up2 from './assets/up2.svg'
import down from './assets/down.svg'
import down2 from './assets/down2.svg'
import ongoing from './assets/ongoing.svg'
import reduce from './assets/reduce.svg'
import add from './assets/add.svg'
import risks from './assets/risks.png'
import upStart from './assets/up-start.svg'
import upEnd from './assets/up-end.svg'
import downStart from './assets/down-start.svg'
import downEnd from './assets/down-end.svg'
import bonus from './assets/bonus.svg'
import win from './assets/win.png'
import lose from './assets/lose.png'

const IMAGE = {
  back, close, record, rule, transferin, transferout, transferin2, transferout2, reset, transferRecord, usdt, fcc, arrowBottom, arrowRight, bull, bull3, bear, bear3, up, up2, down, down2, ongoing, reduce, add, risks,
  upStart, upEnd, downStart, downEnd, bonus, win, lose
}

export default IMAGE