import React, { useEffect, useState } from "react"
import { IMAGE } from "@/assets"
import { Input, Toast } from "antd-mobile"
import { post } from "@/service"
import { JSONifError, floorLocaleString } from "@/tool"
import "./styles.less"

const IndexComponent = (props) => {
  const { asset, payOutRate, toRefreshFunc } = props
  const _amount = !!localStorage.getItem("amount") && localStorage.getItem("amount") !== "" ? localStorage.getItem("amount") : "1"
  const [amount, setAmount] = useState(_amount)
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    if (disableButton) {
      const timer = setTimeout(() => {
        setDisableButton(false)
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [disableButton])

  async function order(direction) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'Place an order',
      'customVariable': direction
    })
    try {
      await post("fight/addOrder", {
        asset: asset,
        amount: amount,
        direction: direction
      })
      localStorage.setItem("amount", amount)
      setTimeout(() => {
        toRefreshFunc()
      }, 2000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSONifError(error)
      })
    }
  }

  return (
    <div className="beat-component">
      <div className="input-container">
        <div className="info">
          <h1>{ asset }</h1>
          {
            amount.length > 0 && <h2>{`Payout: ${floorLocaleString(parseFloat(amount) * (parseFloat(payOutRate) + 1))}`}</h2>
          }
        </div>
        <div className="input-div">
          <img src={IMAGE.reduce} alt="" style={{ opacity: amount === "" || parseFloat(amount) === 1 ? '0.25' : '1.0' }} onClick={() => {
            if (amount === "" || amount === "1") {
              return
            } else {
              const _amount = parseFloat(amount) - 1
              const __amount = Math.max(_amount, 1)
              setAmount(`${__amount}`)
            }
          }} />
          <Input className="input" placeholder="0" value={amount} onChange={(value) => {
            // const regex = /^\d*\.?\d{0,2}$/
            const regex = /^\d+$/
            if (regex.test(value) || value === '') {
              if (value !== "" && parseFloat(value) > 100) return
              setAmount(value);
            }
          }} />
          <img src={IMAGE.add} alt="" style={{ opacity: parseFloat(amount) >= 100 ? '0.25' : '1.0' }} onClick={() => {
            if (amount === "") {
              setAmount("1")
            } else {
              const _amount = parseFloat(amount) + 1
              const __amount = Math.min(_amount, 100)
              setAmount(`${__amount}`)
            }
          }} />
        </div>
      </div>
      <div className="buttons-list">
        <div className={`button bull ${disableButton || amount.length === 0 ? 'bull-disable' : ''}`} onClick={() => {
          setDisableButton(true)
          if (disableButton || amount.length === 0) return
          order("BULL")
        }}>
          <img src={IMAGE.bull} alt="" />
          <div className="desc" style={{ marginRight: '16px' }}>
            <h1>Up</h1>
            <img src={IMAGE.up2} alt="" />
          </div>
        </div>
        <div className={`button bear ${disableButton || amount.length === 0 ? 'bear-disable' : ''}`} onClick={() => {
          setDisableButton(true)
          if (disableButton || amount.length === 0) return
          order("BEAR")
        }}>
          <div className="desc" style={{ marginLeft: '16px' }}>
            <img src={IMAGE.down2} alt="" />
            <h1>Down</h1>
          </div>
          <img src={IMAGE.bear} alt="" />
        </div>
      </div>
    </div>
  )
}
export default IndexComponent