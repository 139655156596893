import { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { Knavbar, Ktool, Kinfo, Kechart, Kbet, Krisks } from "@/component"
import { getQueryVariable, JSONifError } from "@/tool"
import { post } from "@/service"
import { Toast, PullToRefresh } from "antd-mobile"
import "./styles.less"

const statusRecord = {
  pulling: "Pull down to refresh",
  canRelease: "Release immediately refresh",
  refreshing: "Loading...",
  complete: "Refresh successfully",
}

const TABS = ['Real', 'Demo']

const IndexPage = ({ history }) => {
  const userId = getQueryVariable("userId")
  const _tabIndex = sessionStorage.getItem("tabIndex")
  const __tabIndex = !!_tabIndex ? parseInt(_tabIndex) : 0
  const [tabIndex, setTabIndex] = useState(__tabIndex)
  const [data, setData] = useState(null)
  const [reload, setReload] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showRisks, setShowRisks] = useState(false)

  useEffect(() => {
    if (window.flutterSetNavBarStyle) {
      window.flutterSetNavBarStyle.postMessage("none") 
    }
  }, [])

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading...',
          duration: 0
        })
        const resp = await post(`fight/info/v2?userId=${userId}&type=${TABS[tabIndex].toUpperCase()}`)
        localStorage.setItem("token", resp.token)
        setShowRisks(resp.needShowRishConfirm)
        Toast.clear()
        setData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSONifError(error)
        })
      }
    }
    if (!!userId) {
      getData()
    }
  // eslint-disable-next-line
  }, [userId, tabIndex, reload])

  useEffect(() => {
    async function getData() {
      try {
        const resp = await post(`fight/info/v2?userId=${userId}&type=${TABS[tabIndex].toUpperCase()}`)
        setData(resp)
      } catch (error) {}
    }
    if (!!userId) {
      getData()
    }
  // eslint-disable-next-line
  }, [update])
  
  return (
    data ? <div className="home-page">
      <Knavbar title="Bull vs Bear" hideBack={true} />
      <Ktool 
        tabs={TABS}
        tabIndex={tabIndex} 
        change={(_) => setTabIndex(_)}
      />
      <PullToRefresh onRefresh={() => setReload(!reload)} renderText={status => {
        return <div>{statusRecord[status]}</div>
      }}>
        <Kinfo  
          tabIndex={tabIndex}
          data={data} 
          toRefreshFunc={() => setReload(!reload)}
          toUpdateFunc={() => setUpdate(!update)}
        />
        <Kechart 
          symbol={data.symbol}
          tab={TABS[tabIndex]}
          toUpdateFunc={() => setUpdate(!update)}
        />
        <Kbet 
          asset={data.asset}
          payOutRate={data.payOutRate}
          toRefreshFunc={() => setUpdate(!update)}
        /> 
      </PullToRefresh>
      <Krisks 
        show={showRisks} 
        callBackFunc={() => window.closeWebview.postMessage("")}
        toRefreshFunc={() => setReload(!reload)}
      />
    </div> : <div className="home-page">
      <Knavbar title="Bull vs Bear" hideBack={true} />
    </div>
  )
}
export default withRouter(IndexPage)