import React, { useState, useEffect } from "react"
import * as echarts from 'echarts'
import moment from "moment"
import { IMAGE } from "@/assets"
import { post } from "@/service"
import { floorString, ceilString } from "@/tool"
import "./styles.less"

const IndexComponent = (props) => {
  const { symbol, tab, toUpdateFunc } = props
  const [data, setData] = useState([])
  const [xAxisData, setXAxisData] = useState([])
  const [seriesData, setSeriesData] = useState([])
  const [max, setMax] = useState(0)
  const [min, setMin] = useState(0)
  const [reload, setReload] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [originalData, setOriginalData] = useState([])
  const DATALENGTH = 60
  const [originalPoint, setOriginalPoint] = useState([])
  const [addPoints, setAddPoints] = useState([])
  const [originalLine, setOriginalLine] = useState([])
  const [addLines, setAddLines] = useState([])
  const [lastFinishedId, setLastFinishedId] = useState("")

  useEffect(() => {
    lastFinishedId.length !== 0 && toUpdateFunc()
  // eslint-disable-next-line
  }, [lastFinishedId])

  useEffect(() => {
    async function getData() {
      try {
        const res = await post("fight/get_kline", {
          symbol: symbol,
          interval: '1s',
          startTime: moment().subtract(DATALENGTH + 5, 'seconds').valueOf()
        })
        const _data = res.map(subArray => {
          return {
            'timestamp': subArray[0],
            'value': subArray[1],
            'status': true,
          }
        })
        setOriginalData(_data)
      } catch (error) {
      }
    }
    const interval = setInterval(function() {
      if (!!symbol) {
        getData()
      }
    }, 1000) 
    return () => {
      clearInterval(interval)
    }
  // eslint-disable-next-line
  }, [symbol, DATALENGTH])

  useEffect(() => {
    function getData() {
      if (originalData.length === 0) {
        setData([])
      } else if (data.length === 0 || Math.abs(originalData.length - data.length) >= DATALENGTH) {
        const _data = originalData
        const __data = _data.length > DATALENGTH ? _data.slice(0, DATALENGTH) : _data
        setData(__data)
      } else {
        let filteredArray = originalData.filter(function(element) {
          return element.timestamp === data[data.length - 1].timestamp + 1000
        })
        let newData = data.concat()
        if (data.length >= DATALENGTH) {
          newData.shift()
        }
        newData.forEach((element, index) => {
          const _element = originalData.length > index ? originalData[index] : null
          if (_element !== null && element.timestamp === _element.timestamp && !element.status) {
            element.value = _element.value
            element.status = true
          }
        })
        if (filteredArray.length > 0) {
          newData.push(filteredArray[0])
        } else {
          newData.push({
            'timestamp': data[data.length - 1].timestamp + 1000,
            'value': data[data.length - 1].value,
            'status': false,
          })
        }
        const _current = moment().valueOf();
        const _max = data[data.length - 1].timestamp;
        if (_current - _max >= 6000) {
          const filteredArray2 = originalData.filter(function (element) {
            return element.timestamp === data[data.length - 1].timestamp + 2000;
          });
          if (filteredArray2.length > 0) {
            newData.shift();
            newData.push(filteredArray2[0]);
          }
        }
        setData(newData)
      }
      setTimeout(() => {
        setReload(!reload)
      }, 1000)
    }
    getData()
  // eslint-disable-next-line
}, [reload, DATALENGTH])

  useEffect(() => {
    if (data.length > 0) {
      const _seriesData = data.map(subData => subData.value)
      const maxValue = Math.max(..._seriesData)
      const minValue = Math.min(..._seriesData)
      const interval = maxValue - minValue
      const space = interval < 8 ? (8 - interval) * 0.5 : 0
      const _max = ceilString(maxValue + space)
      const _min = floorString(minValue - space)
      setMax(_max)
      setMin(_min)
      // setMax(maxValue)
      // setMin(minValue)
      const _xAxisData1 = data.map(subData => {
        const timestamp = subData.timestamp
        const time = moment(timestamp)
        return time.format('mm:ss')
      })
      const _xAxisData2 = data.map(subData => {
        const timestamp = subData.timestamp
        const time = moment(timestamp).add(60, 'seconds')
        return time.format('mm:ss')
      })
      const _xAxisData = [..._xAxisData1, ..._xAxisData2]
      setSeriesData(_seriesData)
      setXAxisData(_xAxisData) 
    }
  // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    const _originalPoint = [{
      yAxis: seriesData.length === 0 ? 0 : seriesData[seriesData.length - 1],
      xAxis: xAxisData.length === 0 ? 0 : xAxisData[xAxisData.length / 2 - 1],
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        color: '#212121',
        borderWidth: 12,
        borderColor: 'rgba(0, 0, 0, 0.1)'
      },
      animation: true,
      animationDuration: 2000
    }]
    setOriginalPoint(_originalPoint)
  }, [seriesData, xAxisData])

  useEffect(() => {
    const _originalLine = [[
      {
        symbol: 'none',
        label: {
          formatter: `${max}`,
          fontSize: 11,
          color: '#808080',
          distance: 15,
        },
        coord: [xAxisData[0], max],
        lineStyle: {
          color: "#E6E6E6",
          width: 1,
        },
      },
      {
        symbol: 'none',
        coord: [xAxisData[xAxisData.length - 1], max],
        lineStyle: {
          color: "#E6E6E6",
          width: 1,
        },
      }
    ],[
      {
        symbol: 'none',
        label: {
          formatter: `${min}`,
          fontSize: 11,
          color: '#808080',
          distance: 15,
        },
        coord: [xAxisData[0], min],
        lineStyle: {
          color: "#E6E6E6",
          width: 1,
        },
      },
      {
        symbol: 'none',
        coord: [xAxisData[xAxisData.length - 1], min],
        lineStyle: {
          color: "#E6E6E6",
          width: 1,
        },
      }
    ]]
    setOriginalLine(_originalLine)
  }, [max, min, xAxisData])

  useEffect(() => {
    async function getOrder() {
      try {
        const progressResp = await post("fight/playingOrder", {
          status: "PROGRESS",
          type: tab.toUpperCase(),
          duration: 65
        })
        const finishedResp = await post("fight/playingOrder", {
          status: "FINISHED",
          type: tab.toUpperCase(),
          duration: 65
        })
        if (finishedResp.length > 0) {
          setLastFinishedId(finishedResp[finishedResp.length - 1].id)
        }
        const _addPoints = []
        const _addLines = []
        if (!!progressResp && progressResp.length > 0) {
          progressResp.forEach(element => {
            const _startTime = moment(element.startTime)
            const startTime = _startTime.format('mm:ss')
            const price = element.startPrice
            const startSymbol = element.direction === 'BULL' ? `image://${IMAGE.upStart}` : `image://${IMAGE.downStart}`
            const _endTime = moment(element.startTime).add(60, 'seconds')
            const endTime = _endTime.format('mm:ss')
            const endSymbol = element.direction === 'BULL' ? `image://${IMAGE.upEnd}` : `image://${IMAGE.downEnd}`
            const color = element.direction === 'BULL' ? '#9DF8CC' : '#FFCBB9'
            const startPoint = {
              xAxis: startTime,
              yAxis: price,
              symbol: startSymbol,
              symbolSize: [16, 16],
            }
            const endPoint = {
              xAxis: endTime,
              yAxis: price,
              symbol: endSymbol,
              symbolSize: [8, 8],
            }
            const horizontalLine = [
              {
                symbol: 'none',
                coord: [startTime, price],
                lineStyle: {
                  color: color,
                  width: 1,
                  type: 0,
                },
              },
              {
                symbol: 'none',
                coord: [endTime, price],
                lineStyle: {
                  color: color,
                  width: 1,
                  type: 0,
                },
              }
            ]
            const verticalLine = [
              {
                symbol: 'none',
                coord: [endTime, max],
                lineStyle: {
                  color: color,
                  width: 1,
                },
              },
              {
                symbol: 'none',
                coord: [endTime, min],
                lineStyle: {
                  color: color,
                  width: 1,
                },
              }
            ]
            _addPoints.push(startPoint)
            _addPoints.push(endPoint)
            _addLines.push(horizontalLine)
            _addLines.push(verticalLine)
          })
        }
        if (!!finishedResp && finishedResp.length > 0) {
          finishedResp.forEach(element => {
            const price = element.endPrice
            const _endTime = moment(element.startTime).add(60, 'seconds')
            const endTime = _endTime.format('mm:ss')
            const endSymbol = element.userResult === 'WIN' ? `image://${IMAGE.win}` : `image://${IMAGE.lose}`            
            const endPoint = {
              xAxis: endTime,
              yAxis: price,
              symbol: endSymbol,
              symbolSize: [20, 20],
            }
            _addPoints.push(endPoint)
          })
        }
        setAddPoints(_addPoints)
        setAddLines(_addLines)
        setTimeout(() => {
          setRefresh(!refresh)
        }, 2000)
      } catch (error) {}
    }
    getOrder()
  }, [refresh, tab, max, min])

  useEffect(() => {
    let chartDom = document.getElementById('echartID')
    let myChart = echarts.getInstanceByDom(chartDom)
    if (!myChart) {
      myChart = echarts.init(chartDom)
    }
    var option = {
      grid: {
        left: 0,
        right: 75,
        top: 30,
        bottom: 30,
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          fontSize: 11,
          color: function (value, index) {
            return index % 2 === 0 ? '#808080' : 'transparent'
          },
          margin: 11,
          align: 'left',
          // showMaxLabel: true
        }
      },
      yAxis: {
        show: false,
        min: floorString(min, 2),
        max: floorString(max, 2)
      },
      series: [
        {
          data: seriesData,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#212121',
            width: 2
          },
          markLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.2)',
              width: 1,
            },
            data: [...originalLine, ...addLines],
            animation: false,
          },
          markPoint: {
            data: [...originalPoint, ...addPoints]
          }
        }
      ],
    }
    myChart.setOption(option)
  }, [xAxisData, seriesData, max, min, originalPoint, addPoints, originalLine, addLines])

  return (
    <div className="echart-component">
      <div className="head-div">
        <div className="content-div">
          <h1 className="t1">BTC Price</h1>
          <h1 className="t2">{`${seriesData.length === 0 ? '-' : `$${floorString(parseFloat(seriesData[seriesData.length - 1]), 2)}`}`} 
          {
            seriesData.length > 1 && <span style={{ color: seriesData[seriesData.length - 1] >= seriesData[seriesData.length - 2] ? '#10C29F' : '#FF5151' }}>
              {`${ seriesData[seriesData.length - 1] >= seriesData[seriesData.length - 2] ? '+' : '-' }`}{`${parseFloat(Math.abs(seriesData[seriesData.length - 1] - seriesData[seriesData.length - 2]).toFixed(3))}`}
            </span>
          }
          </h1>
        </div>
        <div className="select-button">
          <h2>1 min</h2>
        </div>
      </div>
      <div className="echart-div" id="echartID"></div>
    </div>
  )
}
export default IndexComponent
