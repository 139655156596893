import React, { useState, useEffect } from "react"
import { withRouter, useParams } from "react-router-dom"
import { Knavbar } from "@/component"
import { Toast, PullToRefresh, InfiniteScroll, DotLoading } from "antd-mobile"
import { IMAGE } from "@/assets"
import { statusRecord } from "@/until/constant"
import { post } from "@/service"
import { JSONifError, floorString } from "@/tool"
import moment from "moment"
import "./styles.less"

const pageSize = 20

const IndexPage = ({ history }) => {
  const { asset, type } = useParams()
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [last, setLast] = useState(true)
  const title = type === "battle" ? "Battle Records" : type === "transfer" ? "Transfer History" : ""

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true)
        const url = 
          type === "battle" ? "fight/fight_records/v2" 
        : type === "transfer" ? "fight/transferlist/v2"
        : ""
        const params =
          type === "battle" ? {
            "page": page,
            "pageSize": pageSize,
            "type": asset
          } : {
            page, pageSize, asset
          }
        const resp = await post(url, params)
        const { content, last } = resp
        setLoading(false)
        const _data = page > 1 ? data.concat() : []
        const __data = _data.concat(content)
        setData(__data)
        setLast(last)
      } catch (error) {
        setLoading(false)
        Toast.show({
          icon: 'fail',
          content: JSONifError(error)
        })
      }
    }
    if (!!asset && !!type) {
      getData()  
    }
  // eslint-disable-next-line
  }, [page, last, asset, type])

  function onRefresh() {
    if (loading) return
    setPage(1)
    setLast(true)
  }

  function loadMore() {
    if (loading || last) return
    setTimeout(() => {
      const _page = page + 1
      setPage(_page)
    }, 500)
  }
   
  return (
    <div className="record-page">
      <Knavbar title={title} goBackFunction={() => history.goBack()}  />
      <PullToRefresh onRefresh={onRefresh} renderText={status => {
        return <div>{statusRecord[status]}</div>
      }}>
        <div className="data-list">
        {
          data.map((item, index) => {
            return <>
            {
              type === "battle" ? <div key={index} className="battle-data-item">
                <div className="item-head-div">
                  <div className="amount-div">
                    <img src={item.direction === "BULL" ? IMAGE.up : IMAGE.down} alt="" />
                    <h1>{`${floorString(item.amount)} ${item.asset}`}</h1>
                  </div>
                  {
                    item.result === 'UNKNOWN' ? <img src={IMAGE.ongoing} alt="" className="ongoing-image" /> : <div className="image-div">
                      <img src={IMAGE.bull3} alt="" className="image-bull" style={{
                        zIndex: (item.direction === "BULL" && item.result === 'LOST') || (item.direction === "BEAR" && item.result === 'WIN') ? 1 : 2,
                        mixBlendMode: (item.direction === "BULL" && item.result === 'LOST') || (item.direction === "BEAR" && item.result === 'WIN') ? 'luminosity' : 'normal'
                      }} />
                      <img src={IMAGE.bear3} alt="" className="image-bear" style={{
                        zIndex: (item.direction === "BULL" && item.result === 'WIN') || (item.direction === "BEAR" && item.result === 'LOST') ? 1 : 2,
                        mixBlendMode: (item.direction === "BULL" && item.result === 'WIN') || (item.direction === "BEAR" && item.result === 'LOST') ? 'luminosity' : 'normal'
                      }} />
                    </div>
                  }
                  <h1 className="amount">{ !!item.resultAmount ? `${floorString(item.resultAmount)} ${item.asset}` : '-' }</h1>
                </div>
                <div className="item-content-div">
                  <h2 style={{ flex: '1' }}>{`$${floorString(item.startPrice, 2)}`}</h2>
                  <h2>{ item.result === 'WIN' ? '🎊' : item.result === 'LOST' ? '🤕' : item.result === 'DRAW' ? '🤷‍♂️' : '' }</h2>
                  <h2 style={{ flex: '1', textAlign: 'right' }}>{ !!item.endPrice ? `$${floorString(item.endPrice, 2)}` : '-'}</h2>
                </div>
                <div className="item-content-div">
                  <h2 style={{ flex: '1' }}>{moment(item.startTime).format("YYYY-MM-DD HH:mm:ss")}</h2>
                  <h2>{ item.result === 'WIN' ? 'Win' : item.result === 'LOST' ? 'Lose' : item.result === 'DRAW' ? 'Draw' : '' }</h2>
                  <h2 style={{ flex: '1', textAlign: 'right' }}>{ !!item.endTime ? moment(item.endTime).format("YYYY-MM-DD HH:mm:ss") : '-' }</h2>
                </div>
              </div> :
              type === "transfer" ? <div key={index} className="transfer-data-item">
                <img src={item.direction === "IN" ? IMAGE.transferin2 : IMAGE.transferout2} alt="" className="image"/>
                <div className="content-div">
                  <div className="content-item">
                    {
                      item.status === "IN_REVIEW" ? 
                        <h1 style={{ color: "#FFAA00" }}>⏱ In Review</h1> :
                      item.status === "FINISHED" ?
                        <h1 style={{ color: "#212121" }}>✅ Completed</h1> :
                      item.status === "REJECTED" ?
                        <h1 style={{ color: "#FF5151" }}>⛔ Rejected</h1> :
                        <h1>{ item.status }</h1>
                    }
                    <h1 style={{ color: item.status === "REJECTED" ? '#BFBFBF' : '#000000' }}>{`${item.direction === "IN" ? '+' : '-'} ${Number(Math.abs(item.amount)).toLocaleString()} ${item.asset === "PT" ? "pUSD" : item.asset}`}</h1>
                  </div>
                  <div className="content-item">
                    <h2>{ `${moment(item.time).format("YYYY-MM-DD HH:mm:ss")}` }</h2>
                    <h2>{ !!item.bounsAmount ? `Bonus: ${item.bounsAmount} ${item.asset}` : ''}</h2>
                  </div>
                </div>
              </div> : <></>
            }
            </>
          })
        }
      </div>
        <InfiniteScroll loadMore={loadMore} hasMore={!last}>
        {
          (!last || loading) ? <DotLoading /> : <div></div>
        }
        </InfiniteScroll>
      </PullToRefresh>
    </div>
  )
}
export default withRouter(IndexPage)